(function ($, window, document, undefined) {

  'use strict';

  $(function () {

    if(window.location.pathname == '/kontakt.html'){
      var leftAsideNewsBlock = $('.wrapper aside.left-col .newsblock');
      var leftAsideNewsBlockHtml = leftAsideNewsBlock.html();
      leftAsideNewsBlock.addClass('leftAsideNewsBlockHtmlResponsive');
      $(".wrapper .right-col").append(
        '<div class="newsblock leftAsideNewsBlockHtmlResponsiveClone">' +
        leftAsideNewsBlockHtml
        + '</div>'
    );
    }

    $('header .topmenu').prepend('<div class="mobileMenu"><p>Меню</p><div class="burger"><span></span><span></span><span></span></div> </div>');
    var header       = $('header');
    var menu         = $('header ul.nav.menu');
    var burger       = $('header .mobileMenu .burger');
    var menuHeight   = menu.height() + 20;
    var headerHeight = header.height();
    $('.mobileMenu').click(function () {
      if (menu.is(":visible")) {
        burger.removeClass('active');
        menu.animate({height: "hide"}, 600);
        header.animate({height: headerHeight}, 600);
      } else {
        burger.addClass('active');
        header.animate({height: menuHeight + headerHeight}, 600);
        menu.animate({height: 'show'}, 600);
      }
    });



    var secondMobileMenuText = $('.leftmenu .moduletable h3').text();
    $('.leftmenu .moduletable').prepend('<div class="secondMobileMenu"><p>'+secondMobileMenuText+'</p><div class="burger"><span></span><span></span><span></span></div> </div>');
    var secondMenu         = $('.leftmenu ul.nav.menu');
    var secondBurger       = $('.leftmenu .secondMobileMenu .burger');
    $('.secondMobileMenu').click(function () {
      if (secondMenu.is(":visible")) {
        secondBurger.removeClass('active');
        secondMenu.animate({height: "hide"}, 600);
      } else {
        secondBurger.addClass('active');
        secondMenu.animate({height: 'show'}, 600);
      }
    });



    var waitForFinalEvent = (function () {
      var timers = {};
      return function (callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
          clearTimeout (timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();
    $(window).resize(function(){
      waitForFinalEvent(function(){
        header.removeAttr('style');
        menuHeight   = menu.height() + 20;
        headerHeight = header.height();
        if($(document).width() > 768){
          menu.removeAttr('style');
        }
        if($(document).width() > 1200){
          secondMenu.removeAttr('style');
        }
      }, 200, "menuHelper");
    });


    $('.itemFullText table').wrap('<div class="tableWrapper"></div>');

  });

})(jQuery, window, document);